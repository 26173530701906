@import "../../color-theme.scss";

.card {
    font-family: "Times New Roman", Georgia, Serif;
    background-color: $background-primary;
    color: $text-primary;
    border-radius: 5px;
    padding: 15px 25px;
    border: 1px solid black;
    box-shadow: 2px 2px 5px black;

    h1, h2, h3, h4, ul {
        margin: 0;
    }
}