@import "../../color-theme.scss";

.footer {
    background-color: $background-primary;
    font-size: 1rem;
    padding: 15px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    border-top: solid 1px black;

    p {
        margin: 0;
    }
}