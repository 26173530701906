@import "./color-theme.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-primary;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: $text-highlight;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1 {
  font-size: 2.5rem;
}

h4 {
  font-weight: normal;
  font-size: 1.5rem;
}

hr {
  width: 100%;
  height: 1px;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}

.bold {
  font-weight: bold;
}