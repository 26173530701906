@import "../../color-theme.scss";

.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 150px;

    @media screen and (max-width: 694px) {
        padding: 150px 15px;
        align-items: center;
    }

    h4 {
        @media screen and (min-width: 381px) and (max-width: 425px) {
            font-size: 1.3rem;
        }

        @media screen and (max-width: 380px) {
            font-size: 1.2rem;
        }
    }

    .socialLinksContainer {
        display: flex;
        flex-direction: row;
        gap: 25px;
        justify-content: center;
        align-items: flex-start;
        margin-top: 5px;

        @media screen and (max-width: 560px) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
        }

        .iconLink {
            padding: 10px 10px 7px 10px;
            border-radius: 5px;
            border: 2px solid $background-primary;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            &:hover {
                border: 2px solid $text-highlight;
                text-decoration: none;
            }
        }
    }
}