.portfolioContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    padding: 100px 50px 50px 50px;

    .portfolioItems {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        gap: 15px;
    }
}

.portfolioItemDialog {
    a {
        color: blue;
        &:active {
            color: red;
        }
        &:visited {
            color: purple;
        }
    }
}