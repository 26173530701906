@import "../../color-theme.scss";

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: $background-primary;
    font-size: 1rem;
    padding: 9px 15px;
    position: fixed;
    width: 100%;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    border-bottom: solid 1px black;
    z-index: 10;

    .button {
        padding: 8px 15px;
        text-decoration: none;
        color: $text-faded;
        border-radius: 3px;

        &:first-child {
            color: $text-primary;
        }

        &:hover {
            color: $text-highlight;
        }

        &.active {
            background-color: $background-highlight;
            color: $text-highlight;
        }
    }

    .menuIcon {
        color: $text-faded;
        border-radius: 3px;
        padding: 4px 5px 0 5px;
        border: 2px $background-primary solid;

        &:active {
            border: 2px $text-highlight solid;
        }
    }
}

.navDialog {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $background-primary;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid black;

    .navDialogButton {
        padding: 15px;
        color: $text-primary;

        &.active {
            background-color: $background-highlight;
            color: $text-highlight;
        }
    }

    .closeIconContainer {
        padding: 10px 10px 0px 13px;

        .closeIcon {
            padding: 4px 5px 5px 5px;
            border-radius: 3px;
            border: 2px $background-primary solid;

            &:active {
                border: 2px $text-highlight solid;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .hideOnSmallScreens {
        display: none;
    }
}

@media screen and (min-width: 401px) {
    .showOnSmallScreens {
        display: none;
    }
}