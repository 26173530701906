.coverContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 105px 95px;
    gap: 45px;
    min-height: 100vh;

    @media screen and (max-width: 920px) {
        flex-direction: column;
        justify-content: center;
    }

    @media screen and (max-width: 600px) {
        padding: 45px 15px;
    }

    .headerCard {
        max-width: 380px;
        align-self: flex-start;

        @media screen and (max-width: 600px) {
            h1 {
                font-size: 2.0rem;
            }

            h4 {
                font-size: 1.2rem;
            }
        }
    }
    
    .portrait {
        width: 250px;
        height: 250px;
        align-self: center;
        border-radius: 50%;
        box-shadow: 2px 2px 5px black;

        @media screen and (max-width: 920px) {
            align-self: flex-end;
        }
    }
}