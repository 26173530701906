@import "../../color-theme.scss";

.thumbnailContainer {
    .thumbnail {
        width: 100%;
        border-radius: 3px;
        padding: 2px;
        border: 1px solid black;
        
        &:hover {
            cursor: pointer;
        }
    }
}

.fullImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;

    img {
        border: 2px solid black;
        border-radius: 3px;
    }
}

.closeIconContainer {
    padding: 4px 4px 0px 4px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.9);

    &:hover {
        cursor: pointer;
    }
}